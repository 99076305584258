import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MIconButton } from 'components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import CommonErrorCard from 'components/CommonErrorCard';
import axios from 'utils/axios';
import { commonActions } from 'redux/common';
import { useDispatch } from 'react-redux';
import { localStorageKey } from 'utils/constants/common';
import ReactGA from 'react-ga4';

const CommonContext = createContext({
  showNotification: () => {},
  toggleDialog: () => {},
  closeAlertDialog: () => {},
  pushGaEvent: (name, params) => {}
});

function CommonProvider({ children }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogProps, setDialogProps] = useState({});
  const dispatch = useDispatch();
  axios.interceptors.response.use(undefined, (error) => {
    if (error.response) {
      const { status, data } = error.response;
      const errMsgFromApi = data?.message || data?.msg || 'Error occurred, try later';
      // Check if unauthorized
      if (!_.isEmpty(data) && data?.code === 16) {
        dispatch(commonActions.setReloginDialog(true));
      }
      switch (status || undefined) {
        case 401:
          localStorage.removeItem(localStorageKey.accessToken);
          // window?.location?.reload();
          break;
        case 403:
          break;
        case 400:
          showNotification('error', errMsgFromApi);
          break;
        case 500:
          toggleDialog({
            dialogLabel: 'An error occured',
            dialogContentText: errMsgFromApi,
            buttonLabel: 'go-back',
            buttonLabelClick: () => {
              window.location.replace('/');
              closeAlertDialog();
            }
          });
          break;
        default:
          break;
      }

      return Promise.reject((error.response && error.response.data) || 'Error! Try again later');
    }
    return Promise.reject(error);
  });

  const pushGaEvent = (name, eventParams) => {
    // window?.gtag('event', name, eventParams);
    ReactGA.gtag('event', name, eventParams);
  };

  const showNotification = (variant, message) => {
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  };

  const closeAlertDialog = (event, reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return;
    }
    setDialogProps({});
  };

  const toggleDialog = (props) => {
    setDialogProps(props);
  };

  return (
    <CommonContext.Provider value={{ showNotification, toggleDialog, closeAlertDialog, pushGaEvent }}>
      <CommonErrorCard
        imageSrc="/Error.webp"
        dialogLabel={dialogProps?.dialogLabel}
        dialogContentText={dialogProps?.dialogContentText}
        buttonLabel={dialogProps.buttonLabel}
        buttonLabelClick={dialogProps.buttonLabelClick}
        buttonTextLabel={dialogProps?.buttonTextLabel}
        buttonTextLabelClick={dialogProps?.buttonTextLabelClick}
        handleCloseDialog={closeAlertDialog}
        openDialog={!_.isEmpty(dialogProps)}
      />
      {children}
    </CommonContext.Provider>
  );
}

CommonProvider.propTypes = {
  children: PropTypes.node
};

export { CommonProvider, CommonContext };
